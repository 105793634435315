<template lang="pug">
div
  loading(:active.sync="busy", :is-full-page="true")
  .widget
    .widget-header
      h1.title Profile
    .widget-body
      form(v-on:submit.prevent="save")
        .widget-section-title
          i.la.la-user.mr-2
          | Main Settings
        .form-group
          label Name
          input#name.form-control(type="text", placeholder="Name", v-model="user.name", disabled)
        .form-group
          label Email
          input#email.form-control(type="email", placeholder="Email", v-model="user.email", disabled)
        .form-group
          label New Password
          input#password.form-control(type="password", placeholder="New Password", v-model="form.new_password", autocomplete="new-password")
        div(v-if="user.type==='publisher' && user.permissions['notifications::EMAIL']")
          .widget-section-title
            i.la.la-bell.mr-2
            | Email Notifications
          .form-group
            b-form-checkbox(v-model="user.notify_sub1_change") Subsource Whitelist/Blacklist Changes
        .form-group
          button.btn.btn-primary(type="submit") Save

</template>
<script>
export default {
  name: 'Profile',
  data() {
    return {
      busy: false,
      form: {
        new_password: '',
      },
    }
  },
  methods: {
    async save() {
      this.busy = true;
      const url = 'user/saveProfile';
      const data = {
        new_password: this.form.new_password,
        notify_sub1_change: this.user.notify_sub1_change,
      }
      try {
        const response = await this.$http.post(url, data);
        if (response.data.err === 1) {
          this.$ovNotify.error(response.data.errdesc);
          return;
        }
        this.$ovNotify.success('Profile saved');
        setTimeout(() => {
          this.$store.dispatch('setUser', null);
          this.$router.push({ name: 'login' });
        }, 1500);
      } catch (error) {
        console.error(error);
        this.$ovNotify.error('Failed to save profile');
      } finally {
        this.busy = false;
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.form = { new_password: this.user.password };
  },
}
</script>
